.nav-bar-container {
    height: 5em;
    padding: 0 1.5em;
    background-color: $ac-indigo;
    color: white;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    a {
        color: inherit;
        text-decoration: none;
    }
    .item {
        display: flex;
        flex-direction: row;
        height: 100%;
        align-items: center;
        h1 {
            padding: 0 0.5em;
        }

        &:hover {
            background-color: $ac-purple;
            color: white;
        }

        
    }

    h1 {
        padding: 0;
        margin: 0
    }

    .title {
        display: none;
    }
    @include for-size(tablet){
        justify-content: flex-end;

        .title {
            display: block;
        }

        .home {
            margin-right: auto;

        }
    }
}