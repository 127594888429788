.calendar-month {
    display: flex;
    flex-direction: row;
    justify-content: center;
    
    h1 {
        margin: 0 0.5em;

        &.month {
            width: 5em;
            text-align: center;
        }
    }

    .button {
        cursor: pointer;
        &:hover {
            color: $ac-mint;
        }
    }

}

.calendar-container {
    display: flex;
    flex-direction: column;

    .cell {
        &.day {
            background-color: $ac-pink;
            color: white;
            display: none;
            @include for-size(tablet){
                display: flex;
            }
        }

        &:not(.day) {
            height: 5em;
        }
        flex: 1;

        border: 1px solid $ac-pink;
        .date {
            font-size: 2em;
            font-weight: bold;
            color: $ac-purple;
            text-align: center;
            @include for-size(tablet){
                text-align: left;
            }
        }
        &.X {
            background-color: white;
            color: $ac-pink !important;
            display: none;
            @include for-size(tablet){
                display: block;
            }
        }

        img {
            width: 5em;
            height: 5em;
        }

        .names {
            display: flex;
            flex-direction: column;
            text-align: center;
        }

        
    }

    .calendar-row {
        display: flex;
        
        flex-direction: column;
        @include for-size(tablet){
            flex-direction: row;
        }
        
        div{
            flex: 1;
        }

        &.headings {
            display: none;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            background-color: $ac-pink;
            height: 2em;
            .cell {
                background-color: unset;
                border: none;
                display: flex;
                flex-direction: column;
                justify-content: center;
                text-align: center;

            }
            @include for-size(tablet){
                display: flex;
            }
        }
    }
}