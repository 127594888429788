.page-container {
    margin: 1em 1.5em 1em 1.5em;

    display: flex;
    flex-direction: column;

    &.home {
        h2 {
            margin-top: 1em;
        }

        a {
            text-decoration: none;
            color: $ac-peach;
        }

        img {
            width: 50%;
            align-self: center;
            border-radius: 50%;
        }
    }

    .hemisphere-toggle {
        display: flex;
        flex-direction: row;

        div {
            width: 50%;
            height: 1em;
            padding: 1em;
            border: 2px solid $ac-pink;
            border-radius: 10px;
            color: white;
            text-align: center;
            cursor: pointer;

            &.active {
                background-color: $ac-pink;
            }

            &:not(.active){
                color: $ac-pink;
            }

            &:hover{
                border: 2px solid $ac-peach;
                background-color: $ac-peach;
                color: $ac-white;
            }

        }
    }

    .info-container {
        display: flex;
        flex-direction: row;
        margin: 1em 0;

        .item {
            display: flex;
            flex-direction: row;
            flex: 1;
            justify-content:space-evenly;
            align-items: center;
            height: 3em;
            background-color: $ac-mint;
            color: white;

            &:first-child {
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
            }

            &:not(:first-child){
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
            }

            .time-container {
                display: flex;
                flex-direction: row !important; 
            }
            h1, input {
                display: none;
            }


            h2 {
                display: flex;
            }

            @include for-size(tablet){
                h1, input {
                    display: flex;
                }
    
                h2 {
                    display: none;
                }
            }

        }

       
    }

    .tabs {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 3em;

        .item {
            display: flex;
            flex-direction: row;
            border: 2px solid $ac-pink;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            color: $ac-pink;
            padding: 0.5em;
            flex: 1;
            justify-content: space-evenly;
            cursor: pointer;

            &:hover{
                border: 2px solid $ac-peach;
                background-color: $ac-peach;
                color: $ac-white;
            }

            &.active:not(:hover){
                background-color: $ac-pink;
                color: $ac-white;
            }
        }

        .title {
            display: none
        }

    }

    .info-list-container {
        border-left: 2px solid $ac-pink;
        border-right: 2px solid $ac-pink;
        border-bottom: 2px solid $ac-pink;

        .content {
            // @include for-size(tablet) {
                padding: 1em
            // }
        }
    }
    .info-list {
        display: flex;
        flex-direction: row;
        padding: 0.25em;
        div, h2 {
            flex: 1;
            // padding: 0.5em;
        }

        &.title {
            border-bottom: 2px solid $ac-pink;
        }

        &.title {
            display: none;
            @include for-size(mobile) {
                display: flex;
            }
        }

        &.entry:hover{
            background-color: $ac-pink;
        }

        .image {
            width: 50px;
            flex: unset;
        }

        .price {
            width: 70px;
            flex: unset;
        }

        @include for-size(tablet) {
            .price {
                width: unset;
                flex: 1;
            }
            .image {
                width: 70px;
            }
        }
        img{
            width: 50px;
            height: 50px;
        }

        &.entry div {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
}