body, h1, h2, h3, p {
    margin: 0;
    padding: 0;
    font-family: 'Source Sans Pro', sans-serif;
}

h1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

input {
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    width: 2em;
    border:none;
    background-color: inherit;
    color: white;
    text-align: right;
}

#set-am {
    cursor: pointer;
}

#set-time {
    -webkit-user-select:none; 
    -webkit-touch-callout:none; 
    -moz-user-select:none; 
    -ms-user-select:none; 
    user-select:none; 
    caret-color: transparent;
}